<template>
  <div class="inteLigent">
    <div class="topSearchBox">
      <el-input
        ref="searchInput"
        size="medium"
        v-model="searchContent"
        placeholder="请输入搜索内容"
      ></el-input>
      <el-button type="primary" @click="searchInput">
        搜一搜
      </el-button>
    </div>
    <div class="tabsBox">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="搜文档" name="document"></el-tab-pane>
        <el-tab-pane label="搜题库" name="question"></el-tab-pane>
        <el-tab-pane label="搜图片" name="image"></el-tab-pane>
        <el-tab-pane label="搜视频" name="video"></el-tab-pane>
      </el-tabs>
    </div>
    <div class="listContent">
      <div class="listTitle">
        已为您找到相关结果 {{ listTotal }} 条
      </div>
      <div class="listContentBox" v-loading="dataLoading">
        <div class="contentBox" v-for="(item,index) in dataList" :key="index" @click="chooseContent(item)"
             @mouseover="overContent(index)" @mouseout="activeIndex = null">
          <div class="contentTitle" :style="{color:activeIndex == index?themeColor:'#444A53'}"
               v-html="highlightKeywords(item.sourceName)"></div>
          <div class="content" v-if="searchType==2" v-html="highlightKeywords(item.q)"></div>
          <!--题库展示内容特殊处理-->
          <div class="content" v-else v-html="highlightKeywords(item.q+'</br>'+item.a)"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import {
  inteligentSearch
} from "@/api/knowledgeClassifcation/knowledge";

export default {
  data() {
    return {
      searchContent: '',
      activeName: 'document',
      listTotal: 0,
      searchType: '2',
      dataList: [],
      activeIndex: null,
      dataLoading: false
    }
  },
  computed: {
    ...mapGetters(['themeColor'])
  },
  mounted() {
    this.searchContent = this.$route.query.search;
    if (this.searchContent != '') {
      this.searchInput();
    }
  },
  methods: {
    searchInput() {
      if (this.searchContent == '') {
        this.$message({
          type: "warning",
          message: "请输入搜索内容",
        });
        return;
      }
      this.dataLoading = true;
      inteligentSearch({
        text: this.searchContent,
        searchType: this.searchType
      }).then((res) => {
        console.log(res);
        if (res.data.code == 200) {
          let data = res.data.data.data.list;
          this.listTotal = res.data.data.data.list.length;
          data.forEach((item) => {
            item.q = item.q.slice(0, 100) + '...';
          })
          this.dataList = data;
          this.dataLoading = false;
        }
      })
    },
    handleClick() {
      if (this.searchContent != '') {
        if (this.activeName == 'document') {
          this.searchType = '2';
        } else if (this.activeName == 'image') {
          this.searchType = '3';
          // alert("这里是搜图片")
        } else if (this.activeName == 'video') {
          this.searchType = '4';
          // alert("这里是搜视频")
        } else {
          this.searchType = '1';
        }
        this.searchInput();
      }
    },
    chooseContent(item) {
      if (this.activeName == 'document' || this.activeName == 'image' || this.activeName == 'video') {
        window.open(window.location.origin + `#/knowledgeBrowse?id=${item.kmInfoId}&fileName=${item.sourceName}&isNewWindow=true`);
      } else {
        let obj = {
          id: item.questionsId
        }
        this.$router.push({
          path: '/training/question/eduquestionsView',
          query: {
            row: JSON.stringify(obj),
            type: 'view',
          }
        });
      }
    },
    overContent(index) {
      this.activeIndex = index;
    },
    highlightKeywords(text) {
      const regex = new RegExp(this.searchContent, 'gi');
      text = text.replace(regex, `<span style="text-decoration: underline;color:` + this.themeColor + `">${this.searchContent}</span>`);
      return text;
    }
  }
}
</script>
<style lang="scss" scoped>
.inteLigent {
  width: 100%;
  height: 100%;
  background: #ffffff;
}

.topSearchBox {
  width: 600px;
  margin: auto;
  padding: 24px 0 12px;
  display: flex;
}

.topSearchBox .el-button {
  cursor: pointer;
  height: 40px;
  border-radius: 0 4px 4px 0;
}

::v-deep .el-input--medium .el-input__inner {
  height: 40px !important;
  line-height: 40px !important;
  border-radius: 4px 0 0 4px;
}

.tabsBox {
  width: 600px;
  margin: auto;
}

::v-deep .el-tabs__content {
  display: none;
}

.listContent {
  width: 80%;
  margin: auto;
}

.listTitle {
  width: 100%;
  color: #9295A2;
  font-size: 14px;
  margin-bottom: 15px;
}

.listContentBox {
  width: 100%;
  height: calc(100vh - 300px);
  overflow: auto;
}

.contentBox {
  width: 100%;
  padding: 14px 0;
  border-bottom: 2px dashed #DCDFE6;
  color: #444A53;
  cursor: pointer;
}

.contentBox:hover .contentTitle {
  text-decoration: underline;
}

.contentTitle {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 12px;
}

.content {
  font-size: 14px;
}
</style>
